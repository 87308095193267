<template>
  <v-row
    align="center"
    justify="center"
    :style="{ 'margin-left': marginLeft, 'margin-right': marginRight }"
  >
    <v-col cols="12" sm="8" md="5">
      <v-card class="mt-12 mx-auto py-4 elevation-6">
        <v-card
          color="blue-grey darken-4"
          class="mx-auto my-n10 elevation-6"
          max-width="calc(100% - 32px)"
        >
          <v-card-title class="font-weight-light white--text center">
            {{ $t(content.title) }}
            <v-spacer />
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  elevation="4"
                  dark
                  ripple
                  x-small
                  color="primary"
                  v-on="on"
                  @click="clearAndPush"
                >
                  <v-icon>$mdiLogin</v-icon>
                </v-btn>
              </template>
              <span>{{ $t(content.loginBtn) }}</span>
            </v-tooltip>
          </v-card-title>
        </v-card>
        <v-card-text class="pt-12 pb-0">
          <div class="text-h6 font-weight-light mb-2">
            {{ $t(content.heading) }}
          </div>
          <div class="subheading font-weight-light grey--text">
            <p>
              {{ $t(content.bodytext) }}
            </p>
          </div>
          <v-form v-model="isValid" @submit.prevent="registerLogin(regData)">
            <v-text-field
              :label="$t(content.firstNameLabel)"
              name="firstName"
              type="text"
              required
              :rules="nameRules"
              v-model="registrationData.firstName"
            />
            <v-text-field
              :label="$t(content.lastNameLabel)"
              name="lastName"
              type="text"
              required
              :rules="nameRules"
              v-model="registrationData.lastName"
            />
            <v-text-field
              :label="$t(content.emailLabel)"
              name="email"
              required
              :rules="emailRules"
              v-model="registrationData.email"
            />
            <v-card-actions>
              <v-spacer />
              <v-btn
                type="submit"
                :disabled="!isValid"
                block
                rounded
                color="primary"
                elevation="6"
              >
                {{ $t(content.registrationBtn) }}
              </v-btn>
            </v-card-actions>
          </v-form>
          <alertError class="mt-0" />
          <v-divider class="my-2"></v-divider>
          <v-icon class="mr-2" small color="primary">
            $mdiInformation
          </v-icon>
          <span class="text-caption">{{ $t(content.footnote) }}</span>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable no-console */
import confRegistration from "../conf/confRegistration";
import AlertError from "../components/AlertError";
import { mapGetters, mapActions, mapMutations } from "vuex";
import i18n from "../plugins/i18n";

export default {
  name: "Registration",
  components: { AlertError },

  data: () => ({
    content: { ...confRegistration },
    isValid: true,
    emailRules: [
      v => !!v || i18n.t(confRegistration.emailRules1),
      v =>
        /(^$|^(([A-Za-z0-9+\-_]+(\.[A-Za-z0-9+\-_]+)*)|(.[a-zA-Z0-9]))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([A-Za-z0-9-]+\.)+[a-zA-Z]{2,}))$)/.test(
          v
        ) || i18n.t(confRegistration.emailRules2)
    ],
    nameRules: [v => !!v || i18n.t(confRegistration.nameRules)],
    registrationData: {
      firstName: "",
      lastName: "",
      email: ""
    }
  }),
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    marginLeft() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "0px";
        default:
          return "-12px";
      }
    },
    marginRight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "0px";
        default:
          return "-12px";
      }
    },
    regData() {
      return {
        email: this.registrationData.email,
        name:
          this.registrationData.firstName +
          " " +
          this.registrationData.lastName,
        locale: this.$root.$i18n.locale
      };
    }
  },
  methods: {
    ...mapActions("auth", ["registerLogin"]),
    ...mapMutations({
      global_clearError: "global_clearError",
      set_status: "auth/set_status"
    }),
    clearAndPush() {
      this.global_clearError();
      this.set_status(null);
      this.$router.push("/").catch(e => e);
    }
  }
};
</script>
